<template>
  <div class="container">
    <div class="form-card">
      <div class="card-head">
        <h4 class="card-title">Kullanıcı Ekle</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <TextTemplate
              label="E-mail"
              :value="formData.email"
              keyName="formData.email"
              type="text"
              :className="v$.formData.email.$errors.length > 0 ? 'error' : ''"
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.email.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Telefon"
              :value="formData.mobile_phone"
              keyName="formData.mobile_phone"
              mask="phone"
              type="text"
              @onChangeText="handleText"
            />
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Ad"
              :value="formData.given_name"
              keyName="formData.given_name"
              type="text"
              :className="
                v$.formData.given_name.$errors.length > 0 ? 'error' : ''
              "
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.given_name.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Soyad"
              :value="formData.family_name"
              keyName="formData.family_name"
              type="text"
              :className="
                v$.formData.family_name.$errors.length > 0 ? 'error' : ''
              "
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.family_name.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Müşteri</label>
              <Multiselect
                v-model="formData.customer_set"
                :options="customerList"
                label="name"
                track-by="name"
                :multiple="true"
                placeholder=""
                selectLabel=""
                selectedLabel=""
                deselectLabel=""
              ></Multiselect>
            </div>
          </div>
          <div class="col-md-12">
            <button class="btn btn-filled-primary" @click="addUser">
              Ekle
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Done
    @closeModal="doneModalClose"
    v-if="isCloseModal"
    :doneMsg="popupText"
    :doneTitle="popupTitle"
    @click="closeModal"
    link="/users"
  />
</template>
<script>
import useValidate from "@vuelidate/core";
import { helpers, required, email } from "@vuelidate/validators";
export default {
  data() {
    return {
      v$: useValidate(),
      isCloseModal: false,
      popupText: "",
      popupTitle: "",
      formData: {
        email: "",
        given_name: "",
        family_name: "",
        mobile_phone: "",
        groups: ["CustomerUser"],
        customer_set: [],
      },
      customerList: [],
    };
  },
  validations() {
    return {
      formData: {
        given_name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        family_name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        email: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          email: helpers.withMessage(
            "Lütfen geçerli bir e-mail giriniz.",
            email
          ),
          $autoDirty: true,
        },
      },
    };
  },
  created() {
    this.getCustomers();
    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Kullanıcılar",
        path: "/users",
      },
      {
        title: "Kullanıcı Ekle",
      },
    ]);
  },
  methods: {
    doneModalClose(value) {
      this.isCloseModal = value;
    },
    addUser() {
      const newCustomerSets = [...this.formData.customer_set];
      const postData = { ...this.formData };
      postData.customer_set = newCustomerSets.map((customer) => customer.id);
      this.v$.formData.$touch();
      if (!this.v$.formData.$error) {
        this.$axios.post("/user/", postData).then((response) => {
          this.$router.replace({
            name: "UserEdit",
            params: { id: response.data.id },
          });
        });
      }
    },
    getCustomers() {
      this.$axios.get("/customer/").then((response) => {
        response.data.filter((customer) => {
          this.customerList.push({
            id: customer.id,
            name: customer.name,
          });
        });
      });
    },
    handleText(data) {
      let keynames = data.keyName.split(".");
      keynames.length <= 1
        ? (this[keynames[0]] = data.value)
        : (this[keynames[0]][keynames[1]] = data.value);
    },
  },
};
</script>